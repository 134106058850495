import request from '../utils/request.js'

export function login(data){
    return request({
        url: '/api/system/user/findByid',
        method: 'post',
        data
    })
}
// 测试登录
export function loginByPwdTest(data) {
    return request({
        url: '/api/handler/loginByPwdHandler?phoneNum=18868812220&passWord=121212',
        method: 'get',
        data
    })
}
export function loginByPwd(data) {
    return request({
        url: '/api/account/sign-in',
        method: 'post',
        data
    })
}
export function loginByPhoneBind(data) {
    return request({
        url: '/api/account/sign-in-bind',
        method: 'post',
        data
    })
}
// 发送短信验证码
export function sendCheckCode(data) {
    return request({
        url: '/api/account/sendCheckCode',
        method: 'post',
        data
    })
}
export function logOut(data) {
    return request({
        url: '/api/account/logout',
        method: 'post',
        data
    })
}