<template>
  <div class="login-container">
    <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form" auto-complete="on" label-position="left">
      <div class="body-container">
        <div class="leftimg">
          <img src="../../../src/assets/loginbg.png" alt="" />
        </div>
        <div class="loginin">
          <h2 class="title mb10">职业院校德育管理系统</h2>
          <div class="tabs">
            <div v-for="(item, index) in tab" :key="index" :class="{ tab_active: current == index, tab_item: current != index }" @click="changeTab(index)">
              {{ item }}
            </div>
          </div>

          <el-form-item label-width="40px" class="border tbLine" v-if="current == 0">
            <div slot="label" class="label">
              <i class="el-icon-user"></i>
            </div>
            <el-input v-model="formInline.phoneNum" class="fs20" placeholder="请输入手机号"></el-input>
          </el-form-item>

          <el-form-item label-width="40px" class="border tbLine" v-if="current == 0">
            <div slot="label" class="label">
              <i class="el-icon-lock"></i>
            </div>
            <el-input type="password" class="fs20" v-model="formInline.passWordLogin" placeholder="请输入密码"></el-input>
          </el-form-item>
          
          <el-form-item class="mgt36 " v-if="current == 0">
            <el-button type="primary" @click="submitForm(formInline)" class="login-btn btnLine" round>登 录</el-button>
          </el-form-item>

          <div id="wx_login_container" v-if="current == 1">
            <wxlogin :appid="wxAppID"
                self_redirect="false"
                :scope="'snsapi_login'"
                :redirect_uri='encodeURIComponent(imgBaseUrl + "/api/system/wxuser/code2userinfo")'
                theme="black"
                href="" rel="">
            </wxlogin>
          </div>
        </div>
      </div>
    </el-form>
    <h2 class="copyRight">{{siteRecordNum}}</h2>
  </div>
</template>

<script src="http://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js" type="text/javascript" charset="utf-8"></script>
<script>
  import * as api from "@/api/login";
  import { getCurrUserItem } from "@/api/users";
  import { setToken } from "@/utils/token.js";
  import { getHttpUrl, getWxAppID, getSiteRecordNum } from "@/utils/request";
  import wxlogin from 'vue-wxlogin';
  export default {
    name: "Login",
    components: {
      wxlogin
    },
    data() {
      return {
        imgBaseUrl: getHttpUrl(),
        wxAppID: getWxAppID(),
        siteRecordNum: getSiteRecordNum(), // 备案号
        current: 0, // 当前选中的 tab-index
        tab: [ "账号登录", "扫码登录"],
        formInline: {
          phoneNum: "",
          passWordLogin: "",
          rose: "",
        },
        loginForm: {
          phoneNum: "",
          passWordLogin: "",
        },

        // activeName: "second",
        activeName: "first",

        loginRules: {
          phoneNum: [{ required: true, trigger: "blur" }],
          passWordLogin: [{ required: true, trigger: "blur" }],
        },
        loading: false,
        passwordType: "password",
        redirect: undefined,
      };
    },
    // vue 在模板渲染成html后调用，通常是初始化页面完成后，再对html的dom节点进行一些需要的操作。
    mounted() {
      // 接收url参数
      var query = this.$route.query;
      // ?msg=
      let msg = query.msg;
      if (msg != null && msg.length > 0) {
        this.$message.error(msg);
      }
    },
    methods: {
      // 修改密码
      changePassword() {},
      btn() {
        this.$router.push({
          path: "/home",
        });
      },
      changeTab(index) {
        // 切换 tab
        this.current = index;
      },
      // 执行 登录
      submitForm(formName) {
        if (formName.phoneNum == "" || formName.phoneNum.length < 11) {
          this.$message.error("请输入手机号");
        } if (formName.passWordLogin == "" || formName.passWordLogin == "") {
          this.$message.error("请输入密码");
        } else {
          api.loginByPwd(formName).then((res) => {
            if (res.status == 2) {
              setToken(res.data, "登录");
              this.$message.success(res.msg);
              this.$router.push({
                // 默认打开什么页
                // path: "/userArgument",
                path: "/classRecord",
              });
              this.$nextTick(() => {
                this.getCurrUserItem();
              });
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      },
      // 角色接口
      getCurrUserItem() {
        getCurrUserItem().then((res) => {
          if (res.status == 1) {
            console.log("角色接口")
          }
        });
      },
    },
  };
</script>

<style lang="scss">
  $bg: #205ce2;
  $light_gray: #fff;
  $cursor: #fff;
  .login-container {
    background: $bg;
    .el-input {display: inline-block;height: 47px;width: 100%;border: 1px solid rgba(255, 255, 255, 0.1);
      input {border: 0px;height: 47px;}
    }
    .el-form-item.border {border-bottom: 1px solid #205ce2;}
  }
</style>

<style lang="scss" scoped>
  $bg: #205ce2;
  $dark_gray: #889aa4;
  $light_gray: #eee;

  .tbLine{margin-top:30px;}
  .btnLine{margin-bottom:10px; margin-bottom:20px;font-size: 20px;}
  .fs20{font-size: 20px;}

  #wx_login_container{text-align:center;}
  .login-container {
    min-height: 100vh;
    width: 100%;
    background-color: $bg;
    overflow: hidden;
    .login-form {
      position: relative;
      width: 960px;
      /*height: 570px;*/
      max-width: 100%;
      margin: 0 auto;
      margin-top: 100px;
      /*margin-bottom: 100px;*/
      overflow: hidden;
      border-radius: 8px;
      box-shadow: 1px 1px 4px #ddd;
      background: #ffffff;
    }
    .svg-container {
      padding: 6px 5px 6px 15px;
      color: $dark_gray;
      vertical-align: middle;width: 30px;display: inline-block;
    }

    .body-container {
      width: 100%;
      padding: 24px;
      .leftimg {
        width: 400px;
        height: 400px;
        float: left;
        img {width: 90%; margin: 40px 10px;}
      }
      .loginin {
        float: left;
        width: 450px;
        margin-top: 10px;
        .title {text-align: center;color: #205ce2;font-size: 30px;}
        .forget-password {
          margin-bottom: 6px;
          a { font-weight: normal !important;}
        }
        .label {color: #205ce2;font-size: 20px;line-height: 47px;text-align: center;}
        .login-btn {width: 100%;height: 48px;}
      }
    }
    .show-pwd {position: absolute;right: 10px;top: 7px;font-size: 16px;color: $dark_gray;cursor: pointer;user-select: none;}
  }
  /deep/.el-tabs__nav is-top {display: flex;justify-content: space-around;width: 100%;background: blue;}
  .f1 {height: 500px;width: 75%;margin: 0 auto;}
  .father { position: relative; width: 83%; margin: 0 auto;}
  /deep/.el-tabs__item { width: 100%; padding: 0px;}
  .tabs { font-size: 22px; width: 100%;  margin-bottom: 30px; display: flex; justify-content: space-around;}
  .tab_active { color:#347bf9; cursor: pointer; padding: 10px 40px; border-bottom:2px solid #347bf9;}
  .tab_item { color: #c9c5c5; cursor: pointer; padding: 10px 40px; border-bottom:2px solid white;}

  .copyRightFix{position: fixed;left:0; bottom:20px;width: 100%;text-align:center; color:white; font-size:16px; font-weight:normal;}
  .copyRight{margin-top:60px; margin-bottom:60px; text-align:center; color:white; font-size:16px; font-weight:normal;}
</style>
